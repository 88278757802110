<template>
  <div id="app">
    <router-view />
    <!-- <uploadPage /> -->
  </div>
</template>

<script>
// import uploadPage from './components/uploadPage.vue'

export default {
  name: 'App',
  // components: {
  //   uploadPage
  // }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
