import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'
import axios from 'axios'

Vue.config.productionTip = false

Vue.use(ElementUI);
console.log(process.env.NODE_ENV)
if(process.env.NODE_ENV == 'production'){
  axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
}


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
